import React from "react";
import { parseAsText } from "utils/parser";

import {
  VideoImageStyled,
  VideoStyled,
  VideoTitleStyled,
  VideoTileSyled,
  VideoTeaserStyled,
  VideoButtonStyled,
} from "./styles";

export default function Video({
  videoTitle,
  videoImage,
  sectionVideoButtonTextPlay,
  onClick,
}) {
  return (
    <VideoStyled onClick={onClick}>
      <VideoTileSyled className="video-tile">
        <VideoTitleStyled element="h4">
          {parseAsText(videoTitle)}
        </VideoTitleStyled>
        <VideoTeaserStyled>
          <VideoImageStyled
            width={"400px"}
            maxWidth={"400px"}
            aspectRatio={videoImage[0].width / videoImage[0].height}
            fluid={videoImage[0]}
            objectFit="cover"
          />
          <VideoButtonStyled
            icon="play"
            iconLeft
            iconAnimate={false}
            iconMargin={10}
            isNotLink
          >
            {sectionVideoButtonTextPlay || "play"}
          </VideoButtonStyled>
        </VideoTeaserStyled>
      </VideoTileSyled>
    </VideoStyled>
  );
}
