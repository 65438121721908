import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Image from "components/Image";

import Headline from "components/Headline";
import GridItem from "components/GridItem";
import Slider from "components/Slider";
import Button from "components/Button";

export const VideosSection = styled("section")({
  position: "relative",
  overflowX: "hidden",
  height: "auto",
  width: "auto",
  alignItems: "flex-end",
  justifyContent: "center",
  paddingBottom: 125,
  zIndex: 1,

  [media("tabletFluid")]: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export const VideosBg = styled("div")((props) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundPosition: "center center",
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
}));

export const VideosHeadline = styled(Headline)({
  position: "relative",
  wordBreak: "break-all",
});

export const VideoTitleStyled = styled(Headline)({}, (props) => ({
  marginBottom: props.theme.space,
}));

export const VideosSubTitle = styled(Headline)(
  {
    position: "relative",
    wordBreak: "normal",
  },
  (props) => ({
    marginBottom: props.theme.space,
  })
);

export const VideosGridItem = styled(GridItem)({
  paddingTop: 50,
  alignSelf: "center",

  [media("tabletFluid")]: {
    paddingTop: 0,
  },
});

export const VideosButtonsStyled = styled("div")({
  marginBottom: 20,
  position: "relative",
  zIndex: 1,
});

export const VideoImageStyled = styled(Image)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 0,
});

export const VideoStyled = styled("div")(
  {
    userSelect: "none",
    position: "relative",
    maxWidth: "100%",
    width: "400px",
    cursor: "pointer",
    marginLeft: 25,

    "&:hover": {
      a: { opacity: 1 },

      ".video-tile": {
        transform: "scale(1.1)",
      },
    },
  },
  (props) => {
    return {
      marginBottom: props.theme.space,
    };
  }
);

export const VideoTileSyled = styled("div")({
  position: "relative",
  transition: "all 200ms ease-out",
  transformOrigin: "center bottom",
  pointerEvents: "none",
});

export const VideoTeaserStyled = styled("div")({
  position: "relative",
});

export const VideoButtonStyled = styled(Button)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  whiteSpace: "nowrap",
  opacity: 1,
  zIndex: 1,
  width: "auto",
  pointerEvents: "none",

  [media("tabletFluid")]: {
    opacity: 0.7,
  },
});

export const VideosListStyled = styled(Slider)({});

export const VideosPopupStyled = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  background: "rgba(0,0,0,0.8)",
  zIndex: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&::before": {
    color: "white",
    display: "block",
    content: "'loading...'",
    position: "absolute",
    zIndex: 0,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
});

export const VideosTextStyled = styled("div")({
  ul: {
    listStylePosition: "outside",
    paddingLeft: 18,
  },
});

export const VideosNewsletterPopupStyled = styled("div")(
  {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "rgba(0,0,0,0.8)",
    zIndex: 100,
    transition: "all 300ms ease-out",
  },
  (props) => ({
    opacity: props.newsletterOpen ? 1 : 0,
    pointerEvents: props.newsletterOpen ? "all" : "none",
    transform: props.newsletterOpen ? "translateY(0)" : "translateY(10px)",
  })
);

export const VideosNewsletterWrapperStyled = styled("div")({
  zIndex: 0,
  position: "relative",
  margin: "0 auto",
  maxWidth: "920px",
  padding: 0,

  [media("tabletFluid")]: {
    padding: 100,

    "& > section": {
      padding: "6vw 4vw",
    },
  },
});

export const VideoWrapperStyled = styled("div")({
  zIndex: 0,
  position: "relative",
  width: "100%",
  maxWidth: 1200,
  paddingTop: `${(9 / 16) * 100}%`,
  [media(1200)]: {
    paddingTop: 0,
    height: 675,
  },
});

export const VideoPopupCloseStyled = styled("span")({
  position: "absolute",
  top: 0,
  right: 0,
  padding: 20,
  cursor: "pointer",
  pointerEvents: 0.7,
  zIndex: 1,

  "&:hover": {
    opacity: 1,
  },
});

export const VideosNewsletterPopupCloseStyled = styled("span")({
  position: "absolute",
  top: 0,
  right: 0,
  padding: 20,
  cursor: "pointer",
  opacity: 0.7,
  zIndex: 1,

  "&:hover": {
    opacity: 1,
  },
});
