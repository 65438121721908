import React, { useEffect } from 'react'

import { FormWrap } from './styles'
import { v4 as uuid } from 'uuid'

import { FormStyled } from 'components/EvoForm/FormStyled'
import SwitchTheme from 'components/SwitchTheme'
import Redactor from 'components/Redactor'

export default function Snippet({ id, theme, lawText, buttonText }) {
  const labelId = uuid()

  useEffect(() => {
    const existingScript = document.getElementById('campaignmonitor')
    if (!existingScript) {
      const script = document.createElement('script')
      script.src =
        'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js'
      script.id = 'campaignmonitor'
      document.body.appendChild(script)
    }
  })

  return (
    <SwitchTheme name={theme}>
      <FormWrap theme={theme}>
        <FormStyled>
          <form
            id="subForm"
            className="js-cm-form"
            action="https://www.createsend.com/t/subscribeerror?description="
            method="post"
            data-id={id}>
            <div className="evo-form__row evo-form__row--2">
              <div className="evo-form__input-wrap">
                <input
                  aria-label="Name"
                  id="fieldName"
                  maxLength="200"
                  name="cm-name"
                  placeholder="Name"
                  type="text"
                  required
                  className="js-cm-name-input sc-iwsKbI iMsgpL"
                />
              </div>
              <div className="evo-form__input-wrap">
                <input
                  autoComplete="Email"
                  aria-label="E-Mail-Adresse"
                  id="fieldEmail"
                  maxLength="200"
                  name="cm-gkrkhi-gkrkhi"
                  placeholder="E-Mail-Adresse"
                  required
                  type="email"
                  className="js-cm-email-input"
                />
              </div>
            </div>

            {lawText && (
              <div>
                <div className="evo-form__option-row">
                  <input
                    className="evo-form__input"
                    id={'checkbox-agree-' + labelId}
                    type="checkbox"
                    name={'checkbox-agree-' + labelId}
                    required
                  />
                  <label
                    className="evo-form__label"
                    htmlFor={'checkbox-agree-' + labelId}>
                    <Redactor content={lawText} parseType="content" />
                  </label>
                </div>
              </div>
            )}
            <button
              type="submit"
              className="js-cm-submit-button sc-jKJlTe iPYWCQ">
              {buttonText}
            </button>
          </form>
        </FormStyled>
      </FormWrap>
    </SwitchTheme>
  )
}
