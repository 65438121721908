import { styled, css } from "utils/emotion";
import { toPx } from "utils/strings";
import media from "utils/mediaqueries";

export const EvoFormStyled = styled("section")({}, (props) => ({
  padding: "20px 0",
  position: "relative",

  "#paymentIframe": {
    background: "white",
    padding: "20px 50px",
    boxShadow: "0px 5px 30px rgba(0,0,0,0.6)",

    [media(0, 600)]: {
      padding: "20px 10px",
      margin: "0 -20px",
    },

    iframe: {
      minHeight: "440px",

      [media(0, 1024)]: {
        overflowScrolling: "touch",
        overflowY: "scroll",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 999999,
        background: "white",
        padding: "20px",
      },
    },
  },

  ".evo-form__input-wrap": {
    marginBottom: toPx(props.theme.space),
  },

  ".evo-form": {
    color: "white",
  },

  ".evo-form__info": {
    color: "#120f0a",
  },

  ".evo-form__block": {
    padding: "10px 0px",
  },

  ".evo-form__ellipsis div": {
    background: "white",
  },

  ".evo-form__block + .evo-form__block": {
    borderTop: "1px solid rgba(255,255,255,0.1)",
  },

  ".evo-form__quote": {
    letterSpacing: "0.05em",
    fontSize: "13px",
    lineHeight: "21px",
  },

  ".evo-form__quote table": {
    width: "100%",
  },

  ".evo-form__quote hr": {
    marginTop: "40px",
    opacity: 0.2,
  },

  ".evo-form__quote td, .evo-form__quote th": {
    borderTop: "1px solid rgba(0,0,0,0.2)",
  },

  ".evo-form__quote tr:last-of-type th, .evo-form__quote tr:last-of-type td": {
    borderTop: "1px solid rgba(0,0,0,0.5)",
  },

  ".evo-form__quote td": {
    width: "20%",
  },

  /* Select box */
  /* background base64 arrow (works IE11+) */
  ".evo-form__select-wrap select, .evo-form__input-wrap select": {
    background:
      'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAcCAYAAAAnbDzKAAAAAXNSR0IArs4c6QAAASBJREFUWAnNl1sSgyAMRdEd9qPrqV1PP7rDtgalgwpCkhuEGeURJec4+HIuKuP7NdEWDXXVTPENgdCDD+7h+1/3/NzuU4j1UOf4vMAmGGg7kjjjG5LBjiRKfGNgTdbzkvITJIP2g6fwa/r8Eor5LlhORfiVKX0Tx/Ch3VCiFp7Q/gLU4ZxIx1sULsNGgIC4EyAlJLkPAldJSOCJNSnQWkIKfyrQSkIDXxSwltDCVwlYSSDgqwXQEih4lgBKAgnPFtBKoOFFAlIJC3ixAFfCCl4lUCtBx82vy+VPz3d2O+VHYvZNvEuT7RavbvbMOaCEp6nVAjSJSAIADxNgS4DgoQLVEkB4uEBRAgxvIpCVMIA3EzhIGMFTHtNCTyf/hDLM8gOFJwvn5ovhIQAAAABJRU5ErkJggg==") no-repeat',
    backgroundSize: "12px",
    backgroundPosition: "calc(100% - 20px) center",
    backgroundcolor: "transparent",
    paddingRight: "30px",
  },

  /* form feedback stylings */
  ".evo-form__info-content": {
    transform: "translate(-50%, -50%)",
    left: "50%",
    padding: "50px 40px",
    maxWidth: "430px",
    textAlign: "center",
  },

  ".evo-form__info": {
    borderRadius: "10px",
    background: "rgba(255, 255, 255, 0.95)",
    zIndex: 1,
    border: 0,
  },

  /* override specific css structure coming from evo-form script */
  ".evo-form__row": {
    margin: 0,
  },

  /* agree section (for now, there is only this option-row!) */
  ".evo-form__option-row": {
    marginTop: "20px",

    a: {
      color: props.theme.borderColorTertiary,
    },
  },

  ".evo-form__row .evo-form__select + .evo-form__input, .evo-form__row .evo-form__input + .evo-form__input":
    {
      marginTop: 0,
    },

  ".evo-form__row--2 .evo-form__input-birthdate": {
    background: "transparent",
  },

  ".evo-form__row--2 .evo-form__input-birthdate-year": {
    "border-bottom-left-radius": 0,
    "border-top-left-radius": 0,
    padding: "10px",
  },

  ".evo-form__row--2 .evo-form__input-birthdate-day, .evo-form__row--2 .evo-form__input-birthdate-month, .evo-form__row--2 .evo-form__input-birthdate-year":
    {
      maxWidth: "60px",
    },

  ".evo-form__row--2 .evo-form__input-birthdate-day": {
    "border-bottom-right-radius": 0,
    "border-top-right-radius": 0,
    padding: "10px",
  },

  ".evo-form__row--2 .evo-form__input-birthdate-month": {
    borderRadius: 0,
    padding: "10px",
  },

  ".evo-form__row--2 .evo-form__input-birthdate-caption": {
    paddingTop: "20px",
    fontSize: "15px",
    textTransform: "uppercase",
    fontFamily: props.theme.fontPrimary,
    fontWeight: "bold",
    borderBottom: props.theme.inputBorderBottom,
    height: "56px",
  },

  [media(800)]: {
    /* bit more space please between */
    ".evo-form__row--2 .evo-form__input-wrap + .evo-form__input-wrap": {
      marginLeft: "40px",
    },
    ".evo-form__row--2 .evo-form__input-wrap": {
      maxWidth: "calc(50% - 20px)",
    },
  },

  h2: {
    fontFamily: props.theme.fontSecondary,
    fontSize: "20px",
    textTransform: "uppercase",
    fontWeight: 900,
    letterSpacing: ".1em",
    marginBottom: "1em",
    lineHeight: 1.3,
  },
}));

export const StyledFormWrapper = styled("div")({}, (props) => ({
  maxWidth: props.fullWidth ? 950 : 748,
  margin: "0 auto",

  [media("tabletFluid")]: {
    marginRight: props.alignment === "right" ? 0 : "auto",
    padding: props.theme.space,
  },
}));

export const StyledDiv = styled("div")({}, (props) => ({
  maxWidth: 748,
  margin: "0 auto",

  [media("tabletFluid")]: {
    marginRight: props.alignment === "right" ? 0 : "auto",
    padding: props.theme.space,
  },
}));

// Evo form is only "default (dark)" theme for now !!!
export const FormStyled = styled("div")({}, (props) => ({
  // "&": {
  //   overflow: "hidden",
  // },

  "input, textarea, select, fieldset": {
    marginBottom: toPx(props.theme.space),
  },

  "input[type='radio']": {
    marginBottom: 0,
  },

  "input[type='email'], input[type='number'], input[type='search'], input[type='text'], input[type='tel'], input[type='url'], input[type='password'], textarea, select":
    {
      fontSize: 15,
      fontFamily: props.theme.fontPrimary,
      fontWeight: "bold",
      height: toPx(props.theme.spaceDoubleEight),
      padding: `${toPx(
        props.theme.spaceQuarter
      )} 0` /* The 6px vertically centers text on FF, ignored by Webkit */,
      color: props.theme.inputColor,
      backgroundColor: "transparent",
      borderRadius: 0,
      border: 0,
      borderBottom: `1px solid ${props.theme.inputBorderColorBottom}`,
      boxShadow: "none",
      boxSizing: "border-box",
      appearance: "none",
      outline: "none",

      "&:focus": {
        borderBottom: `1px solid ${props.theme.inputBorderBottomColorFocus}`,
      },

      "&:valid": {
        borderBottom: `1px solid ${props.theme.inputBorderBottomColorValid}`,
      },

      "&:invalid": {
        borderBottom: `1px solid red`,
      },

      "&:placeholder-shown": {
        borderBottom: `1px solid ${props.theme.inputBorderBottomColor}`,
        textTransform: "uppercase",
        color: props.theme.inputPlaceholderColor,
      },
    },

  textarea: {
    minHeight: 65,
    paddingTop: 6,
    paddingBottom: 6,
  },

  "input:focus, textarea:focus": {
    border: 0,
    borderColor: "white",
  },

  "label, legend": {
    color: props.theme.colorPrimary,
    fontFamily: props.theme.fontSecondary,
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },

  fieldset: {
    padding: 0,
    borderWidth: 0,
  },

  "input[type='checkbox'], input[type='radio']": {
    display: "inline-block",
  },

  /* prevent native select arrow in IE11 */
  "select::-ms-expand": {
    display: "none",
  },

  /* force dark color for options (IE11, non-iOS devices) */
  "select option": {
    color: "#000",
  },

  "select:invalid, select option[value='']": {
    color: "rgba(2, 176, 171, 0.8)",
  },

  input: {
    "::placeholder": {
      color: props.theme.inputPlaceholderColor,
      opacity: 1,
    },
  },

  "input[type='radio'], input[type='checkbox']": {
    position: "absolute",
    opacity: 0,
    width: 0,
  },

  "input[type='number']::-webkit-outer-spin-button, input[type='number']::-webkit-inner-spin-button":
    {
      appearance: "none",
      margin: 0,
    },

  "input[type='number']": {
    appearance: "textfield",
  },

  "input[type='radio'] + label, input[type='checkbox'] + label": {
    position: "relative",
    cursor: "pointer",
    padding: 0,
    marginLeft: 40,
    color: "rgba(255, 255, 255, 0.7)",
  },

  "input[type='radio'] + label:before, input[type='checkbox'] + label:before": {
    content: "' '",
    marginRight: 20,
    display: "inline-block",
    verticalAlign: "text-top",
    width: 24,
    height: 24,
    background: "transparent",
    border: `1px solid ${props.theme.checkboxBorderColor}`,
    borderRadius: 4,
    boxSizing: "border-box",
    position: "absolute",
    left: -40,
  },

  "input[type='radio']:checked + label:before, input[type='checkbox']:checked + label:before":
    {
      background: "transparent",
    },

  "input[type='radio']:checked + label:after, input[type='checkbox']:checked + label:after":
    {
      content: "' '",
      position: "absolute",
      left: -31,
      top: 6,
      width: 4,
      height: 8,
      border: `solid ${props.theme.checkboxMarkColor}`,
      borderWidth: "0 2px 2px 0",
      transform: "rotate(45deg)",
    },

  /* button */

  button: {
    marginTop: "50px !important",
    display: "inline-block",
    textTransform: "uppercase",
    fontFamily: props.theme.fontPrimary,
    fontSize: "14px !important",
    cursor: "pointer",
    color: props.theme.buttonGreenColorHover,
    border: `1px solid ${props.theme.buttonGreenBorderColorHover}`,
    borderRadius: toPx(props.theme.borderRadiusPrimary),
    boxShadow: "none",
    backgroundColor: props.theme.buttonGreenBackgroundHover,
    transition: "all 0.1s linear",
    fontWeight: 700,
    letterSpacing: "0.05em",
    textDecoration: "none",
    width: "100%",
    padding: `17px ${toPx(props.theme.spaceDouble)} !important`,
    "&:hover, &:focus, &:active": {
      color: props.theme.buttonGreenColor,
      border: `1px solid ${props.theme.buttonGreenBorderColor}`,
      backgroundColor: props.theme.buttonGreenBackground,
      boxShadow: "none",
    },
  },
}));
