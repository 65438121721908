import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

export const SectionNewsletterStyled = styled("section")({}, props => ({
  position: "relative",
  background: props?.theme?.sectionTextBackground || null,
  padding: "24px 0",
  zIndex: 1,
  padding: props.sectionPadding === "large" ? "65px 0 35px" : "24px 0",

  [media("tablet")]: {
    padding: props.sectionPadding === "large" ? "130px 0 70px" : "70px 0"
  },

  ul: props.listStyleImage
    ? {
        listStyleImage: `url(${props.listStyleImage})`,
        listStylePosition: "outside",
        paddingLeft: props?.listStyleImageWidth || 20
      }
    : null,

  li: props.listStyleImage
    ? {
        paddingLeft: 20,
        marginBottom: 10
      }
    : null
}));

export const NewsletterContentWrapStyled = styled("div")({}, props => ({
  whiteSpace: "pre-line"
}));

export const ButtonsWrapStyled = styled("div")(props => ({}));

export const FormWrap = styled("div")(
  {
    input: {
      width: "100%"
    },
    ".evo-form__row": {
      marginTop: 50
    },
    ".evo-form__input-wrap": {},
    [media(800)]: {
      /* bit more space please between */
      ".evo-form__row--2 .evo-form__input-wrap + .evo-form__input-wrap": {
        marginLeft: "40px"
      },
      ".evo-form__row--2 .evo-form__input-wrap": {
        maxWidth: "calc(50% - 20px)"
      }
    },

    ".evo-form__option-row p": {
      margin: 0,
      fontSize: 12,
      lineHeight: 1.5,
      marginTop: "0.3em"
    }
  },
  props =>
    props.theme?.name === "green" && {
      input: {
        borderBottomColor: "black"
      }
    },
  props =>
    props.theme?.name === "alternative" && {
      input: {
        borderColor: "black"
      }
    }
);
