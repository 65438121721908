import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import SwitchTheme from "components/SwitchTheme";
import ImageStripe from "components/ImageStripe";
import Grid from "components/Grid";
import GridItem from "components/GridItem";
import Headline from "components/Headline";
import Redactor from "components/Redactor";
import Snippet from "./Snippet";

import { parseAsText } from "utils/parser";

import { SectionNewsletterStyled } from "./styles";

export default function Newsletter({
  headline,
  headlineTag,
  text,
  textBelow,
  padding,
  theme,
  image,
  bulletImage,
  segmentId,
  buttonText,
  specialLawText,
  pageContext,
  className,
}) {
  const textContent = text?.content;
  const textBelowContent = textBelow?.content;
  const listStyleImage = bulletImage?.[0]?.url;
  const listStyleImageWidth = bulletImage?.[0]?.width;

  let globalLawText = null;
  if (pageContext) {
    const staticData = useStaticQuery(graphql`
      query GlobalLawTextQueryStatic {
        craft {
          globalLawTextDe: globals(siteId: 1) {
            ...GlobalLawTextQuery
          }

          globalLawTextEn: globals(siteId: 2) {
            ...GlobalLawTextQuery
          }
        }
      }
    `);

    const { globalLawTextDe, globalLawTextEn } = staticData?.craft;

    globalLawText =
      pageContext.siteId === 1 ? globalLawTextDe : globalLawTextEn;
  }

  const lawText =
    specialLawText?.content ||
    globalLawText?.newsletterLawText?.newsletterLawText?.content ||
    null;

  return (
    <SwitchTheme name={theme}>
      <SectionNewsletterStyled
        className={className}
        listStyleImage={listStyleImage}
        sectionPadding={padding}
        listStyleImageWidth={listStyleImageWidth}
      >
        <Grid wrapReverse>
          <GridItem mobile={1} tabletFluid={1 / 2}>
            {headline && (
              <Headline element={headlineTag}>{parseAsText(headline)}</Headline>
            )}
            {textContent && (
              <Redactor content={textContent} parseType="content" />
            )}
          </GridItem>
          {image?.[0] && (
            <GridItem mobile={1} tabletFluid={1 / 2}>
              <ImageStripe
                args={{ maxWidth: 500 }}
                fluid={image?.[0]}
                aspectRatio={image?.width / image?.height}
                stripePosition="left"
              />
            </GridItem>
          )}
        </Grid>
        <Grid>
          <GridItem desktop={2 / 3}>
            {textBelowContent && (
              <Redactor content={textBelowContent} parseType="content" />
            )}
          </GridItem>
        </Grid>
        <Grid>
          <GridItem mobile={1} laptop={2 / 3} desktop={1 / 2}>
            <Snippet
              id={segmentId}
              theme={theme}
              lawText={lawText}
              buttonText={buttonText || "anmelden"}
            />
          </GridItem>
        </Grid>
      </SectionNewsletterStyled>
    </SwitchTheme>
  );
}

export const query = graphql`
  fragment GlobalLawTextQuery on Craft_GlobalsSet {
    newsletterLawText {
      newsletterLawText {
        content
      }
    }
  }
`;
