import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { parseAsText } from 'utils/parser'
import useLocalStorage from 'utils/hooks/useLocalStorage'

import SwitchTheme from 'components/SwitchTheme'
import TextVisual from 'components/TextVisual'
import Overlay from 'components/Overlay'
import Grid from 'components/Grid'
// import FullscreenVideo from "components/FullscreenVideo";
import Icon from 'components/Icon'
import Redactor from 'components/Redactor'
import Button from 'components/Button'
import ReactPlayer from 'react-player/lazy'
import NewsletterComponent from 'components/Newsletter'
import Video from './Video'

import {
  VideosSection,
  VideosBg,
  VideosHeadline,
  VideosSubTitle,
  VideosGridItem,
  VideosListStyled,
  VideosButtonsStyled,
  VideosPopupStyled,
  VideosTextStyled,
  VideoWrapperStyled,
  VideoPopupCloseStyled,
  VideosNewsletterPopupStyled,
  VideosNewsletterPopupCloseStyled,
  VideosNewsletterWrapperStyled,
} from './styles'

/**
 * Videos component.
 */
export default function Videos({ pageBuilder, pageContext }) {
  const [videoUrl, setVideoUrl] = useState(false)
  const [newsletterOpen, setNewsletterOpen] = useState(false)
  const [lastVideo, setLastVideo] = useLocalStorage('lastVideo', false)

  const {
    sectionVideoHeadline,
    sectionVideoSubtitle,
    sectionTextVisual,
    sectionVideoText,
    sectionVideoTheme,
    sectionVideoBgImage,
    sectionVideoCategories,
    sectionVideoNewsletterSgementId,
    sectionVideoNewsletterButtonText,
    sectionVideoNewsletterLawText,
    sectionVideoButtonTextSubscribe,
    sectionVideoButtonTextWatchTrailer,
    sectionVideoButtonTextPlay,
    sectionVideoButtonTextResume,
    sectionVideoTrailer,
    sectionVideoNewsletterTitle,
  } = pageBuilder

  const sectionVideoTextContent = sectionVideoText?.content

  return (
    <SwitchTheme name={sectionVideoTheme}>
      <VideosSection>
        {/* <FullscreenVideo videoUrl={sectionVideoBgVideo} /> */}
        <VideosBg backgroundImage={sectionVideoBgImage?.[0]?.url_1920} />
        <Overlay background="overlayRadialGradientBackground" />
        <Grid height="auto" paddingTop={120} center>
          {sectionTextVisual && (
            <TextVisual top="30%">{parseAsText(sectionTextVisual)}</TextVisual>
          )}
          <VideosGridItem mobile={1} desktop={4 / 5}>
            {sectionVideoHeadline && (
              <VideosHeadline element="h2" uppercase margin={'0 0 25px 0'}>
                {parseAsText(sectionVideoHeadline)}
              </VideosHeadline>
            )}
            {sectionVideoSubtitle && (
              <VideosSubTitle element="h4">
                {sectionVideoSubtitle}
              </VideosSubTitle>
            )}
            {sectionVideoTextContent && (
              <VideosTextStyled>
                <Redactor
                  content={sectionVideoTextContent}
                  parseType="content"
                />
              </VideosTextStyled>
            )}
            <VideosButtonsStyled>
              {lastVideo && (
                <Button
                  icon="play"
                  iconLeft
                  iconAnimate={false}
                  iconMargin={10}
                  onClick={() => setVideoUrl(lastVideo)}
                  isNotLink>
                  {sectionVideoButtonTextResume || 'resume'}
                </Button>
              )}
              {sectionVideoTrailer?.[0]?.videoId && (
                <Button
                  icon="play"
                  iconLeft
                  iconAnimate={false}
                  iconMargin={10}
                  onClick={() => setVideoUrl(sectionVideoTrailer[0].videoId)}
                  isNotLink>
                  {sectionVideoButtonTextWatchTrailer || 'watch trailer'}
                </Button>
              )}
              {sectionVideoNewsletterSgementId && (
                <Button
                  themeName="green"
                  iconLeft
                  filled
                  onClick={() => setNewsletterOpen(true)}
                  isNotLink>
                  {sectionVideoButtonTextSubscribe || 'subscribe'}
                </Button>
              )}
            </VideosButtonsStyled>
          </VideosGridItem>
        </Grid>
        {sectionVideoCategories.length > 0 &&
          sectionVideoCategories.map((category, index) => {
            return (
              <div key={`category-${index}`}>
                {category.headline && (
                  <div style={{ paddingLeft: 20 }}>
                    <VideosHeadline element="h3" uppercase margin={0}>
                      {parseAsText(category.headline)}
                    </VideosHeadline>
                  </div>
                )}
                {category.videos.length > 0 && (
                  <VideosListStyled
                    noPadding
                    scrollContainerStyle={{ paddingTop: 30 }}>
                    {category.videos.map((video) => {
                      return (
                        <Video
                          key={video.videoId}
                          videoTitle={video.videoTitle}
                          videoImage={video.videoImage}
                          sectionVideoButtonTextPlay={
                            sectionVideoButtonTextPlay
                          }
                          onClick={() => {
                            setLastVideo(video.videoVimeoID)
                            setVideoUrl(video.videoVimeoID)
                          }}
                        />
                      )
                    })}
                  </VideosListStyled>
                )}
              </div>
            )
          })}
        {videoUrl && (
          <VideosPopupStyled>
            <VideoPopupCloseStyled
              onClick={() => {
                setVideoUrl(false)
              }}>
              <Icon type="closeBig" />
            </VideoPopupCloseStyled>
            <VideoWrapperStyled>
              <ReactPlayer
                // onReady={handleVideoReady}
                width="100%"
                height="100%"
                url={`https://vimeo.com/${videoUrl}`}
                playing
                style={{ position: 'absolute', top: 0, left: 0 }}
              />
            </VideoWrapperStyled>
          </VideosPopupStyled>
        )}
        {sectionVideoNewsletterSgementId && (
          <VideosNewsletterPopupStyled newsletterOpen={newsletterOpen}>
            <VideosNewsletterPopupCloseStyled
              onClick={() => setNewsletterOpen(false)}>
              <Icon type="closeBig" />
            </VideosNewsletterPopupCloseStyled>
            <VideosNewsletterWrapperStyled>
              <NewsletterComponent
                headline={sectionVideoNewsletterTitle}
                headlineTag="h2"
                segmentId={sectionVideoNewsletterSgementId}
                buttonText={sectionVideoNewsletterButtonText}
                specialLawText={sectionVideoNewsletterLawText}
                pageContext={pageContext}
              />
            </VideosNewsletterWrapperStyled>
          </VideosNewsletterPopupStyled>
        )}
      </VideosSection>
    </SwitchTheme>
  )
}

export const query = graphql`
  fragment PageBuilderVideosQuery on Craft_PageBuilderVideos {
    sectionVideoHeadline
    sectionVideoSubtitle
    sectionTextVisual
    sectionVideoText {
      content
    }
    sectionVideoTheme
    sectionVideoBgImage {
      ...CraftImageFluid
    }
    sectionVideoCategories {
      headline
      videos {
        ... on Craft_Video {
          videoId: id
          videoTitle: title
          videoImage {
            ...CraftImageFluid
          }
          videoVimeoID
        }
      }
    }
    sectionVideoButtonTextSubscribe
    sectionVideoButtonTextWatchTrailer
    sectionVideoButtonTextResume
    sectionVideoButtonTextPlay
    sectionVideoBgVideo
    sectionVideoNewsletterSgementId
    sectionVideoNewsletterButtonText
    sectionVideoTrailer {
      ... on Craft_Video {
        videoId: id
      }
    }
    sectionVideoNewsletterTitle
    sectionVideoNewsletterLawText {
      content
    }
  }
`
