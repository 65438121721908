import { styled } from 'utils/emotion';
import Image from 'components/Image';

export default styled(Image)(
  {
    position: 'relative',
  },
  props => ({
    '&:after': {
      display: props.hasStripe ? 'block' : 'none',
      content: '""',
      position: 'absolute',
      top: '100%',
      left: props.stripePosition === 'left' && '0',
      right: props.stripePosition === 'right' && '0',
      height: '8px',
      width: '50%',
      background: props.theme.imageStripeBackground,
      zIndex: props.zIndex || 'auto',
    },
  }),
);
